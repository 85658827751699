/* Start with a base tropical color palette */
:root {
  --tropical-blue: #0077b6;
  --tropical-green: #0096c7;
  --sandy-beach: #ffd166;
  --sunset-pink: #ef476f;
  --palm-green: #06d6a0;
}

.home-container {
  font-family: "Poppins", sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.title,
.sub-title {
  text-align: center;
  color: var(--tropical-green);
  margin-bottom: 0.5em;
}

.divider {
  border-top: 2px solid var(--sandy-beach);
  width: 50%;
  margin: auto;
  margin-bottom: 2em;
}

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.intro-image,
.coach-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.introduction-text,
.coach-description p {
  color: #333;
  line-height: 1.6;
  text-align: justify;
  margin-bottom: 1em;
}

.benefits-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  background-color: var(--palm-green);
  color: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.join-button {
  background-color: var(--sunset-pink);
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 50px;
  margin: 30px auto;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: block;
  width: max-content;
  align-self: center;
}

.join-button:hover {
  background-color: var(--tropical-blue);
  transform: translateY(-3px);
}

.coach-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.coach-description {
  background-color: var(--sandy-beach);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-footer {
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  font-style: italic;
  background-color: var(--tropical-blue);
  color: white;
}

/* Add responsiveness to benefit cards */
@media (max-width: 768px) {
  .benefits-container {
    grid-template-columns: 1fr;
  }
}
.menu-container {
  padding-left: 2%;
  color: rgb(240, 92, 6) !important;
  background-color: #333;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
body {
  margin-top: 100px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.coach-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  margin: 0 auto;
  padding: 0 20vw;
}
.navbar {
  padding-left: 2vw !important;
  font-size: 2vw !important;

  color: rgb(205, 122, 7) !important;
}
